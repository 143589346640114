import * as yup from 'yup';

import { validatePassword, validatePasswordConfirm, validateNewPasswordConfirm } from 'schema/validation/password';

export const CHANGE_PASSWORD_SCHEMA = yup.object().shape({
    password: yup.string().test('Password validation', validatePassword),
    passwordConfirm: yup.string().test('Password Confirm Matches', validatePasswordConfirm)
});

export const SECURE_CHANGE_PASSWORD_SCHEMA = yup.object().shape({
    currentPassword: yup.string().required(),
    newPassword: yup.string().test('Password validation', validatePassword),
    newPasswordConfirm: yup.string().test('Password Confirm Matches', validateNewPasswordConfirm)
});
